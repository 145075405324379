import Script from "next/script";

type IProps = {
  gtmId: string;
};

export const GoogleTagManager = ({ gtmId }: IProps) => {
  return (
    <Script
      id="google-tag-manager"
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-T4LVH6Q8QN"
    >
      <Script
        id="google-analytics"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${gtmId}');`,
        }}
      ></Script>
    </Script>
  );
};

interface PageviewParams {
  page_path: string;
}

export const triggerPageview = (url: string, gtmId?: string): void => {
  const params: PageviewParams = {
    page_path: url,
  };
  if (typeof window !== "undefined") {
    window.gtag("config", "G-T4LVH6Q8QN", params);
  } else {
    console.warn("unable to fire google analytics pageview");
  }
};

interface EventParams {
  eventName: string;
  category: string;
  label: string;
  value: number;
}

export const triggerEvent = ({
  eventName,
  category,
  label,
  value,
}: EventParams): void => {
  if (window.gtag) {
    console.log("Fire event: ", eventName);
    window.gtag("event", eventName, {
      event_category: category,
      event_label: label,
      value: value,
    });
  } else {
    console.warn("unable to fire google analytics event");
  }
};
