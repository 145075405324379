import "../styles/globals.css";
import type { AppProps } from "next/app";
import { client } from "../lib/apollo";
import { ApolloProvider } from "@apollo/client";
import Layout from "../components/Layout";
import styled from "styled-components";
import { Analytics } from "@vercel/analytics/react";
import { GoogleTagManager, triggerPageview } from "../utils/GoogleTagManager";
import { useRouter } from "next/router";
import { useEffect } from "react";

const Theme = styled.div`
  font-size: 22px;
  font-family: "Michroma", sans-serif;
`;

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      /* invoke analytics function only for production */
      triggerPageview(url.href);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
  // eslint-disable-next-line react/jsx-props-no-spreading

  return (
    <ApolloProvider client={client}>
      <GoogleTagManager gtmId={"G-T4LVH6Q8QN"}></GoogleTagManager>
      <Theme>
        <Layout>
          <Component {...pageProps} />
          <Analytics />
        </Layout>
      </Theme>
    </ApolloProvider>
  );
}

export default App;
